.navbar {
  $background-color: #fff;
  $filter: saturate(250%) blur(6px);
  background: rgba($color: $background-color, $alpha: 0.925) !important;
  @supports (backdrop-filter: $filter) or (-webkit-backdrop-filter: $filter) {
    background: rgba($color: $background-color, $alpha: 0.75) !important;
    -webkit-backdrop-filter: $filter;
    backdrop-filter: $filter;
  }

  a {
    width: fit-content;
    transition-duration: $animation-speed;
    transition-property: transform;
    :not(.disabled) {
      color: rgba($foreground-color, 0.8) !important;
    }
  }

  .active > a,
  a:hover:not(.disabled),
  a:active:not(.disabled),
  a:focus:not(.disabled) {
    background: $signature-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a:hover,
  a:active,
  a:focus {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .navbar-brand > img {
    height: $navbar-height - 1rem;
  }
}
