.release {
  $jumbotron-height: 70vh;
  $filter-height: 32rem;

  .release-jumbotron {
    background-image: $signature-gradient;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: $jumbotron-height;
    min-height: 28rem;
  }

  .release-jumbotron-filter {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.75) 0,
      rgba(0, 0, 0, 0) $filter-height
    );
  }

  .azure-deploy-button {
    height: 31px;
  }
}
