.carousel-item > div {
  background: $signature-gradient;
  height: 30rem;
}

.carousel-control-next,
.carousel-control-prev {
  max-width: 10vw;
  min-width: 2rem;
}
