.not-found {
  $jumbotron-height: 25vh;
  $filter-height: 16rem;

  a {
    color: $foreground-color;
  }

  .not-found-jumbotron {
    background-image: $signature-gradient;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: $jumbotron-height;
    min-height: 14rem;
  }
}
