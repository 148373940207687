$standard-font: "Work Sans", "Sarabun", sans-serif;

$depa-yellow: #fff200;
$vistec-red: #a6253b;
$vistec-purple: #52348c;

$signature-gradient: linear-gradient(
  111.94deg,
  $depa-yellow 0%,
  $vistec-red 65%,
  $vistec-purple 100%
);

$background-color: #f5f5f5;
$foreground-color: #303030;
$standard-box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.25);
$animation-speed: 0.3s;
$navbar-height: 4.75rem;

body {
  background: $background-color;
  color: $foreground-color;
  font-family: $standard-font;
  border-radius: 0 !important;

  // CSS hack to fix anchored link being displayed behind navbar.
  [id]:before {
    content: "";
    display: block;
    height: $navbar-height;
    margin-top: -$navbar-height;
    visibility: hidden;
    z-index: -999999;
  }

  table {
    border-bottom: 1px solid #808080;
    border-top: 1px solid #808080;
    margin-bottom: .5rem;
  }

  th {
    border-bottom: 2px solid #808080;
    border-top: 2px solid #808080;
    padding: 0 .5rem;
  }

  td {
    border-bottom: 1px solid #808080;
    border-top: 1px solid #808080;
    padding: 0 .5rem;
  }

  .font-weight-semibold {
    font-weight: 600;
  }

  .card-stack {
    .card-stack-item {
      min-height: 18.75rem;
      box-shadow: $standard-box-shadow;
      z-index: 0;
      position: relative;
    }

    .card-genie {
      transition-duration: $animation-speed;
      transition-property: transform;
    }

    .card-genie:hover,
    .card-genie:focus,
    .card-genie:active {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
    }

    .card-bg-filter {
      top: 0;
      left: 0;
      $filter: blur(2px);
      background: rgba($color: $foreground-color, $alpha: 0.75) !important;
      @supports (backdrop-filter: $filter) or (-webkit-backdrop-filter: $filter) {
        background: rgba($color: $foreground-color, $alpha: 0.4) !important;
        -webkit-backdrop-filter: $filter;
        backdrop-filter: $filter;
      }
      z-index: 2;
    }

    .card-bg {
      top: 0;
      left: 0;
      z-index: 1;
      background: $signature-gradient;
      background-size: cover;
    }

    .card-item-content {
      z-index: 3;
    }
  }

  .supporters-container {
    background: $foreground-color;
    box-shadow: $standard-box-shadow;

    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
    }

    .supporter-logo-container {
      background-color: $background-color;
      transition-duration: $animation-speed;
      transition-property: transform;
    }

    .supporter-logo-container:hover,
    .supporter-logo-container:focus,
    .supporter-logo-container:active {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }

  article * {
    max-width: 100%;
  }
}
