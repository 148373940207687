footer {
  font-size: 0.8rem;

  a {
    color: $foreground-color;
  }

  .social-row {
    a {
      display: inline;
      transition-duration: $animation-speed;
      transition-property: transform;
    }
    a:hover,
    a:focus,
    a:active {
      display: inline;
      background: $signature-gradient;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  #depa-footer-logo {
    height: 3.125rem;
  }

  #vistec-footer-logo {
    height: 1.5625rem;
  }

  .footer-logos {
    transition-duration: $animation-speed;
    transition-property: transform;
  }

  .footer-logos:hover,
  .footer-logos:focus,
  .footer-logos:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
